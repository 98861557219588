import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";
import { formattedPrice } from "../../../helpers/formatted_price";
import mixpanel from "../../../helpers/mixpanel";

export default class extends Controller {

  static values = { businessDays: Array }

  static targets = ["editDateModal",
                    "startDate",
                    "BoxSelectStartDate",
                    "optionalStartDate",
                    "cityFriendlyName",
                    "cleaningFrequency",
                    "cleaningIntensity",
                    "totalDaysArray",
                    "cleaningTotalPrice",
                    "cleaningHasDiscount",
                    "boxTotalMonth",
                    "modifyPlanModal",
                    "modifyPlanForm",
                    "modifyPlanButtonsContainer",
                    "billingInfoForm"]

  connect() {
    this.totalPriceMonth(this.startDateTarget.value);
  }


  showModifyPlanModal(event) {
    event.preventDefault();
    this.modifyPlanModalTarget.classList.add("is-active");
  }

  modifyPlan(event) {
    event.preventDefault();
    const buttonsContainer = this.modifyPlanButtonsContainerTarget;
    buttonsContainer.classList.remove("is-justify-content-space-between-auto");
    buttonsContainer.classList.add("is-justify-content-center-auto", "is-relative");
    buttonsContainer.style.height = "1.5rem";
    buttonsContainer.innerHTML = '<div class="modify-plan-modal-preloader"></div>';
    mixpanel.track("track-button-continue-summary-client");
    Turbo.cache.clear();
    window.history.replaceState(null, null, "/client");
    this.modifyPlanFormTarget.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
  }

  showModal(event) {
    event.preventDefault();
    mixpanel.track("track-summary-services-edit-date");
    this.editDateModalTarget.classList.add("is-active");
    this.toggleClassBoxSelected();
  }

  closeModal(event) {
    event.preventDefault();
    const modal_name = event.currentTarget.getAttribute("data-modal-name");
    this[`${modal_name}Target`].classList.remove("is-active");
  }

  selectStartDate(event) {
    const date_elements = event.currentTarget.querySelectorAll(".sort-date");
    const abbreviated_day = date_elements[0].textContent.substring(0, 3);
    const day_number = date_elements[1].textContent;
    const month_name = date_elements[2].textContent;

    this.totalPriceMonth(event.currentTarget.getAttribute("data-start-date"));
    this.startDateTarget.value = event.currentTarget.getAttribute("data-start-date");
    this.toggleClassBoxSelected();
    this.optionalStartDateTarget.textContent = `${abbreviated_day}, ${day_number} de ${month_name}`;
  }

  confirmDate(event) {
    event.preventDefault();
    if (this.startDateTarget.value == "") {
      flashAlert("Por favor, seleccione una fecha de inicio");
    } else {
      flashAlert("Fecha de inicio actualizada", "success");
      this.editDateModalTarget.classList.remove("is-active");
    }
  }

  toggleClassBoxSelected() {
    this.BoxSelectStartDateTargets.forEach((element) => {
      if (element.getAttribute("data-start-date") == this.startDateTarget.value){
        element.classList.add("box_date_selected");
      } else {
        element.classList.remove("box_date_selected");
      }
    });
  }

  totalPriceMonth(start_date){
    const business_days = this.businessDaysValue;
    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const cleaning_total_days = this.totalDaysArrayTarget.value;
    const cleaning_total_price = this.cleaningTotalPriceTarget.value;
    const cleaning_has_discount = this.cleaningHasDiscountTarget.value;

    const format_start_date = new Date(`${start_date}T00:00:00`);
    const format_total_days = cleaning_total_days.split(" ").map( n => parseInt(n));

    const last_day_month = new Date(format_start_date.getFullYear(), format_start_date.getMonth() + 1, 0)

    let total_services = 0;

    const type_of_week = this.typeOfWeek(format_start_date);

    let frequency = cleaning_frequency == "once_a_week" ? "weekly" : "biweekly";

    while(format_start_date <= last_day_month){

      let date_to_string = format_start_date.toISOString().split('T')[0];

      if (format_total_days.includes(format_start_date.getDay()) && business_days.includes(date_to_string)){
        if(frequency == "weekly"){
          total_services += 1;
        }else{
          switch (type_of_week) {
            case "odd":
              if (this.typeOfWeek(format_start_date) == "odd") {
                total_services += 1;
              }
              break;
            case "even":
              if (this.typeOfWeek(format_start_date) == "even"){
                total_services += 1;
              }
              break;
          }
        }
      }
      format_start_date.setDate(format_start_date.getDate() + 1);
    }

    const total_price_per_month = cleaning_total_price * total_services;
    const formatted_total_price_per_month = formattedPrice(total_price_per_month);
    const total_label = this.boxTotalMonthTarget.querySelector("p");
    total_label.textContent = `${formatted_total_price_per_month} COP`;

    if (cleaning_has_discount == "true"){
      this.boxTotalMonthTarget.classList.add("box-has-background-cheaper-price");
    }else{
      this.boxTotalMonthTarget.classList.add("box-has-background-summary-price");
    }
  }

  typeOfWeek(date){
    const number_week = Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) / (86400000 * 7)) + 1;
    return number_week % 2 === 1 ? "odd" : "even";
  }

  sendToFillInfo(event){
    event.preventDefault();
    Turbo.cache.clear();
    window.history.replaceState(null, null, "/client");
    this.billingInfoFormTarget.submit()
  }
}