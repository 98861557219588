import { Controller } from "stimulus";
import {
  HelperText,
  removeHelperText,
  HelperTextForServiceDuration,
  removeLoader
} from "../../../helpers/helper_text";
import mixpanel from "../../../helpers/mixpanel";

export default class extends Controller {
  static values = {
    scheduleServiceUrl: String,
    duration: Object
  }
  static targets = [ "boxSingleService",
                     "cleaningFrequency",
                     "cleaningIntensity",
                     "addressId"]

  selectIntensity(event) {
    const target = this.boxSingleServiceTargets[this.boxSingleServiceTargets.length - 1];
    const radioInput = event.currentTarget.querySelector('input[type="radio"]');
    removeHelperText(target);
    const button = document.querySelector(".button.is-primary.is-button-medium");
    removeLoader(button);
    this.boxSingleServiceTargets.forEach((box) => {
      box.classList.remove("box_selected");
    });
    event.currentTarget.classList.add("box_selected");
    if (radioInput) {
      radioInput.checked = true;
      this.cleaningIntensityTarget.value = radioInput.value;
    }

    const { hours, minutes } = this.durationValue[this.cleaningIntensityTarget.value];
    removeHelperText(target, "helper-text");
    HelperTextForServiceDuration(target, hours, minutes);
  }

  sendSchedulePage(event) {
    event.preventDefault();
    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const cleaning_intensity = this.cleaningIntensityTarget.value;
    const schedule_service_url = this.scheduleServiceUrlValue;

    if(!cleaning_intensity || cleaning_intensity === "nil"){
      const message = "Por favor selecciona la jornada en la que necesitas los servicios de limpieza.";
      const target = this.boxSingleServiceTargets;
      HelperText(target[target.length - 1], message, "alert");
      return;
    }

    const address_id = this.hasAddressIdTarget ? this.addressIdTarget.value : null;
    let url = `${schedule_service_url}?cleaning_frequency=${cleaning_frequency}&cleaning_intensity=${cleaning_intensity}`;

    if (address_id) {
      url += `&address_id=${address_id}`;
    }
    mixpanel.track(event.target.dataset.mixpanelEventName);
    Turbo.cache.clear();
    Turbo.visit(url, { action: "replace" });
  }

}